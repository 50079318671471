<template>
  <FlotoModule>
    <template v-if="hasPermission">
      <RouterView :key="$route.fullPath" />
    </template>
  </FlotoModule>
</template>

<script>
import { PageMethods } from '@state/modules/page'
import { TechnicianMethods } from '@state/modules/technician'
import Config from '../config'
import { PreferenceComputed } from '@state/modules/preference'

export default {
  name: 'UsersModule',
  page() {
    return {
      title: this.$tc('user', 2),
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (
        to.name === Config.routeNamePrefix &&
        ['requester', 'technician'].indexOf(to.params.userType) === -1
      ) {
        return vm.$router.replace({ name: '404' })
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    // add any module level clean up logic here
    // refresh vuex
    this.fetchTechnicians()
    setTimeout(() => {
      if (this.userPageReset) {
        this.userPageReset()
      }
    }, 400)
    next()
  },
  data() {
    return {
      hasPermission: false,
    }
  },
  computed: {
    ...PreferenceComputed,
  },
  watch: {
    '$route.params.userType': function (newValue, prevValue) {
      if (this.userPageReset) {
        this.userPageReset()
      }
    },
  },
  created() {
    if (this.myAllowedModules.indexOf('admin.users') === -1) {
      return this.$router.replace({ name: '404' })
    }
    this.hasPermission = true
  },
  methods: {
    ...PageMethods,
    ...TechnicianMethods,
  },
}
</script>

<template>
  <FlotoModule>
    <RouterView :key="$route.fullPath" />
  </FlotoModule>
</template>

<script>
import { authComputed } from '@state/modules/auth'
import { LicenseComputed } from '@state/modules/license'
import { BrandingComputed } from '@state/modules/branding'
import { SupportPortalConfigComputed } from '@state/modules/support-portal-config'

export default {
  name: 'SupportPortalModule',
  page() {
    return {
      title: `${this.branding.customerPortalTitle}`,
    }
  },
  beforeRouteEnter(to, from, next) {
    // vm is this
    next((vm) => {
      if (!vm.allowRequesterToAccessKB && /all-articles/.test(to.name)) {
        return vm.$router.replace({ name: '404' })
      }
      if (
        !vm.allowRequesterToAccessMyApprovals &&
        /my-approvals/.test(to.name)
      ) {
        return vm.$router.replace({ name: '404' })
      }
      if (
        vm.loggedIn &&
        !vm.allowRequesterToAccessServiceRequest &&
        /request-service|service-list/.test(to.name)
      ) {
        return vm.$router.replace({ name: '404' })
      }
      if (
        !vm.loggedIn &&
        !vm.allowGuestToRequestForService &&
        /request-service|service-list/.test(to.name)
      ) {
        return vm.$router.replace({ name: '404' })
      }
      if (
        vm.availableModulesInLicense.indexOf(vm.$constants.SERVICE_CATALOG) ===
          -1 &&
        /service-list|request-service/.test(to.name)
      ) {
        return vm.$router.replace({ name: 'upgrade-plan' })
      }
      if (
        vm.availableModulesInLicense.indexOf(vm.$constants.ASSET) === -1 &&
        /my-assets/.test(to.name)
      ) {
        return vm.$router.replace({ name: 'upgrade-plan' })
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    // add any module level clean up logic here
    next()
  },
  computed: {
    ...authComputed,
    ...BrandingComputed,
    ...LicenseComputed,
    ...SupportPortalConfigComputed,
  },
}
</script>

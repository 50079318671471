<template>
  <FlotoContentLoader :loading="requestLoading" :height="900">
    <component :is="useFormHelpterProvider ? 'FormHelperProvider' : 'div'">
      <FormConsumer
        v-if="formFields.length"
        ref="formRef"
        :key="renderCount"
        :form-fields="formFields"
        :module-name="$constants.REQUEST"
        :value="value"
        :with-submit="withSubmit"
        apply-form-rules
        :allow-field-mapping="
          allowRequesterToCreateIncidentBehalfOfOtherRequester
        "
        :focus-event-brodcast="focusEventBrodcast"
        :avoid-default-value="avoidDefaultValue"
        @change="$emit('change', $event)"
        @subject-blur="$emit('subject-blur', $event)"
        @description-blur="$emit('description-blur', $event)"
        @reset-form="$emit('reset-form', $event)"
        @requester-details="$emit('requester-details', $event)"
        @submit="handleFormSubmitted"
      >
        <template v-slot:submit>
          <span />
        </template>
        <template v-slot:reset>
          <span />
        </template>
        <template v-slot:additional-fields>
          <MCol class="ml-2 mb-4" :size="12">
            <LinkAssetDrawer
              v-if="
                useLinkAsset && allowToLinkAsset && loggedIn && hasAssetModule
              "
              :default-selected-assets="selectedAssets"
              @change="handleLinkAssetSelectionChange"
            />
          </MCol>
          <MCol v-if="!loggedIn" class="ml-2">
            <Captcha
              :key="captchaRenderCount"
              v-model="captcha"
              auto-focus
              action="requestFormCaptcha"
              @is-offline-captcha="isOfflineCaptcha = $event"
            />
          </MCol>
        </template>
      </FormConsumer>
    </component>
  </FlotoContentLoader>
</template>

<script>
import FormHelperProvider from '@components/form-helper/form-helper-provider.vue'
import IsEqual from 'lodash/isEqual'
import FindIndex from 'lodash/findIndex'
import Captcha from '@components/captcha/captcha'
import { LicenseComputed } from '@state/modules/license'
import { FormActions, FormComputed } from '@state/modules/form'
import FormConsumer from '@components/form-consumer.vue'
import { PreferenceComputed } from '@state/modules/preference'
import LinkAssetDrawer from '@modules/ticket/components/link-asset-drawer'
import {
  SupportPortalConfigComputed,
  SupportPortalConfigMethods,
} from '@state/modules/support-portal-config'
import { authComputed } from '@state/modules/auth'
import {
  FieldMappingComputed,
  FieldMappingMethods,
} from '@state/modules/field-mapping'
import { getUserApi } from '@modules/users/users-api'
// import { getFormRulesApi } from '@modules/form-rules/form-rules-api'
import { getFieldByRequesterAccess } from '../helpers/field-access'
import { TechnicianMethods } from '@state/modules/technician'

export default {
  name: 'SupportPortalTicketForm',
  components: {
    FormHelperProvider,
    Captcha,
    FormConsumer,
    LinkAssetDrawer,
  },
  model: {
    event: 'change',
  },
  props: {
    // eslint-disable-next-line
    useTemplate: { type: Boolean, default: true },
    // eslint-disable-next-line
    useRequester: { type: Boolean, default: true },
    // eslint-disable-next-line
    useLinkAsset: { type: Boolean, default: true },
    // eslint-disable-next-line
    useTransitionModel: { type: Boolean, default: true },
    // eslint-disable-next-line
    useFormHelpterProvider: { type: Boolean, default: true },
    withSubmit: { type: Boolean, required: true },
    avoidDefaultValue: { type: Boolean, default: false },
    value: {
      type: Object,
      default() {
        return {}
      },
    },
    defaultSelectedAssets: {
      type: Array,
      default() {
        return []
      },
    },
    focusEventBrodcast: { type: Boolean, default: false },
  },
  data() {
    return {
      creatingRequest: false,
      selectedAssets: [],
      formFields: [],
      captcha: {
        captcha: '',
        token: null,
      },
      managerName: '',
      renderCount: 1,
      captchaRenderCount: 1,
      isOfflineCaptcha: false,
    }
  },
  computed: {
    ...LicenseComputed,
    ...PreferenceComputed,
    ...authComputed,
    ...FormComputed,
    ...SupportPortalConfigComputed,
    ...FieldMappingComputed,
    hasAssetModule() {
      return this.availableModulesInLicense.indexOf(this.$constants.ASSET) >= 0
    },
    customFields() {
      const enablePriorityMatrix =
        this.tenantPrefrences.EnablePriorityMatrix &&
        this.tenantPrefrences.EnablePriorityMatrix.value
      let allFields = getFieldByRequesterAccess(
        this.requestPortalEditableFields,
        this.user
      )
      if (this.useRequester === false) {
        allFields = allFields.filter((f) => f.paramName !== 'requester')
        allFields = allFields.filter((f) => f.inputType !== 'api')
      }
      if (this.useTemplate === false) {
        allFields = allFields.filter((f) => f.inputType !== 'template')
      }
      if (this.useTransitionModel === false) {
        allFields = allFields.filter((f) => f.inputType !== 'transition_model')
      }
      if (enablePriorityMatrix) {
        allFields = allFields.filter((f) => f.inputType !== 'priority')
      }
      // refactor requester_email to requester
      const i = FindIndex(allFields || [], { paramName: 'requester' })
      if (i !== -1) {
        allFields = [
          ...allFields.slice(0, i),
          {
            ...allFields[i],
            attributes: {
              ...(allFields[i].attributes || {}),
              disabled:
                !!this.loggedIn &&
                !this.allowRequesterToCreateIncidentBehalfOfOtherRequester,
              showHint: false,
            },
            defaultValue: (this.user || {}).name,
          },
          ...allFields.slice(i + 1),
        ]
      }
      return allFields
    },
  },
  watch: {
    defaultSelectedAssets: {
      immediate: true,
      handler(newValue, prevValue) {
        if (!IsEqual(newValue, prevValue)) {
          this.selectedAssets = [...(newValue || [])]
        }
      },
    },
    user: {
      immediate: true,
      handler: 'getManagerNameFromUser',
    },
    customFields: {
      immediate: true,
      handler: 'getFormFields',
    },
    managerName: {
      immediate: true,
      handler: 'getFormFields',
    },
  },
  created() {
    let p = Promise.resolve()
    if (this.allowGuestToCreateIncident === undefined) {
      p = this.fetchConfigs()
    }
    p.then(() => {
      if (this.loggedIn) {
        this.requestFieldMappingFetch()
      }
      if (this.loggedIn || this.allowGuestToCreateIncident) {
        this.fetchRequestForm()
      } else {
        this.$router.push(
          this.$modules.getModuleRoute('auth', 'login', {
            query: { redirectFrom: this.$route.path },
          })
        )
      }
    })
    const assignee = this.requestPortalFields.find(
      (field) => field.paramName === 'technicianId'
    )
    const customAsSystemTechnicianField = this.requestPortalFields.find(
      (field) => field.fieldReferenceType === 'technician'
    )
    if (
      !this.loggedIn &&
      this.allowGuestToCreateIncident &&
      (((assignee || {}).requesterGroups || []).length === 0 ||
        customAsSystemTechnicianField)
    ) {
      this.fetchTechnicians()
    }
  },
  methods: {
    ...FormActions,
    ...SupportPortalConfigMethods,
    ...FieldMappingMethods,
    ...TechnicianMethods,
    submit() {
      this.$refs.formRef.submit()
    },
    handleRefreshCaptcha() {
      this.captchaRenderCount++
    },
    handleFormSubmitted(data) {
      // @TODO captcha
      const captcha = {
        ...(this.captcha.token
          ? {
              token: this.captcha.token,
            }
          : {}),
        ...(this.captcha.captcha
          ? {
              captcha: this.captcha.captcha,
            }
          : {}),
      }
      if (
        this.isOfflineCaptcha &&
        this.captcha.token &&
        !this.captcha.captcha
      ) {
        return
      }
      this.$emit(
        'submit',
        {
          ...data,
          linkAssetIds: this.selectedAssets.map((a) => a.id),
          ...(this.allowRequesterToCreateIncidentBehalfOfOtherRequester
            ? {}
            : { requesterId: (this.user || {}).id }),
        },
        captcha
      )
    },
    handleLinkAssetSelectionChange(items) {
      this.selectedAssets = items
      if (!this.withSubmit) {
        this.$emit('change', {
          ...this.value,
          linkAssetIds: items.map((a) => `${a.id}:${a.model}`),
        })
      }
    },
    getManagerNameFromUser(user) {
      if (user && user.id && user.managerId) {
        getUserApi('requester', user.managerId).then((data) => {
          this.managerName = data.name
          setTimeout(() => {
            if (this.$refs.formRef) {
              this.$refs.formRef.handleRequesterSelected(user, true)
            }
          }, 100)
        })
      } else if (user) {
        setTimeout(() => {
          if (this.$refs.formRef) {
            this.$refs.formRef.handleRequesterSelected(user, true)
          }
        }, 100)
      }
    },
    getFormFields() {
      const managerName = this.managerName
      const allFields = this.customFields
      /** * start for user field value mapping in request field ****/
      const avilableFieldIdList = allFields.map((f) =>
        f.isSystemField ? f.paramName : `${f.id}`
      )
      const mapping = ((this.requestFieldMapping || {}).mapping || []).filter(
        (m) => avilableFieldIdList.indexOf(m.value) >= 0
      )
      if (mapping.length > 0) {
        const requesterData = { ...this.user, managerId: managerName }
        const systemFieldValue = {}
        const customFieldValue = {}
        const updatedRequestFieldForFormRules = {}
        mapping.forEach((item) => {
          const userValue = /^\d+$/.test(item.key)
            ? ((requesterData || {}).fieldValueDetails || {})[+item.key]
            : (requesterData || {})[item.key]
          if (/^\d+$/.test(item.value)) {
            customFieldValue[item.value] = userValue
          } else {
            systemFieldValue[item.value] = userValue
          }
          if (userValue) {
            updatedRequestFieldForFormRules[+item.value] = userValue
          }
        })
        this.formFields = allFields.map((field) => {
          if (
            field.isSystemField &&
            Object.keys(systemFieldValue).indexOf(field.paramName) >= 0
          ) {
            return {
              ...field,
              defaultValue: systemFieldValue[field.paramName],
            }
          } else if (
            !field.isSystemField &&
            Object.keys(customFieldValue).indexOf(`${field.id}`) >= 0
          ) {
            return {
              ...field,
              defaultValue: customFieldValue[`${field.id}`],
            }
          } else {
            return field
          }
        })
        setTimeout(() => {
          if (
            Object.keys(updatedRequestFieldForFormRules).length &&
            this.$refs.formRef
          ) {
            this.$refs.formRef.handleFieldBlur(updatedRequestFieldForFormRules)
          }
        })
      } else {
        this.formFields = allFields
      }
      if (!this.loggedIn && this.allowGuestToCreateIncident) {
        this.formFields = this.formFields.map((f) => {
          if (f.isSystemField && f.inputType === 'requester') {
            return { ...f, title: this.$tc('requester_email_field') }
          }
          return f
        })
      }
      this.renderCount = this.renderCount + 1
      /** * end for user field value mapping in request field ****/
    },
  },
}
</script>

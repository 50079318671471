<template>
  <FlotoContentLoader :loading="loading" class="min-h-0">
    <FlotoForm
      ref="formRef"
      class="flex flex-col flex-1"
      @submit="handleSubmit"
    >
      <MRow :gutter="0" class="mb-4 px-4">
        <MCol :lg="12" :sm="12" auto-size>
          <MRow class="flex-no-wrap">
            <MCol auto-size>
              <ImageSelector
                ref="profileSelector"
                circle
                :value="formData.avatar"
                @remove="handleRemoveAvatar"
              />
            </MCol>
            <MCol class="pl-4">
              <MRow>
                <div v-if="!formData.id && usePassword" style="display: none">
                  <input type="password" tabindex="-1" />
                </div>
                <MCol :size="6">
                  <FlotoFormItem
                    id="name"
                    v-model="formData.name"
                    :wrapper-col="{ xs: 5 }"
                    :label="$tc('full_name')"
                    :placeholder="$t('name')"
                    rules="required|short_text"
                    @blur="generateProfilePic"
                  />
                </MCol>
                <MCol :size="6">
                  <FlotoFormItem
                    id="email"
                    v-model="formData.email"
                    :wrapper-col="{ xs: 5 }"
                    rules="email"
                    :placeholder="$tc('email')"
                    :label="$tc('email')"
                    @blur="handleOnBlur"
                  />
                </MCol>
                <MCol :size="6">
                  <FlotoFormItem
                    id="logon-name"
                    v-model="formData.userLogOnName"
                    name="logon-name"
                    :disabled="formData.ldapUser"
                    :label="$tc('logon_name')"
                    :placeholder="$tc('logon_name')"
                  />
                </MCol>
                <MCol v-if="!formData.id && usePassword" :size="6">
                  <PasswordInput
                    id="password"
                    v-model="formData.password"
                    :label="$t('password')"
                    vid="password"
                    :rules="{ required: !formData.id }"
                    :wrapper-col="{ xs: 5 }"
                    :placeholder="$t('password')"
                  />
                </MCol>
                <MCol v-if="!formData.id && usePassword" :size="6">
                  <PasswordInput
                    id="confirm-password"
                    v-model="formData.confirmPassword"
                    :label="$t('confirm_password')"
                    :rules="{
                      confirmed: 'password',
                      required: !formData.id,
                    }"
                    :wrapper-col="{ xs: 5 }"
                    :placeholder="$t('confirm_password')"
                  />
                </MCol>
                <MCol :size="6">
                  <FlotoFormItem
                    id="contact-no"
                    v-model="formData.contactNo"
                    rules="new_contact_number|max:50"
                    :label="$tc('contact_no')"
                    :placeholder="$tc('contact_no')"
                  />
                </MCol>
                <MCol :size="6">
                  <FlotoFormItem
                    id="alt-contact-1"
                    v-model="formData.altContactNo1"
                    rules="new_contact_number|max:50"
                    :label="$tc('alt_contact_no1')"
                    :placeholder="$tc('alt_contact_no1')"
                  />
                </MCol>
                <MCol :size="6">
                  <FlotoFormItem
                    id="alt-contact-2"
                    v-model="formData.altContactNo2"
                    rules="new_contact_number|max:50"
                    :label="$tc('alt_contact_no2')"
                    :placeholder="$tc('alt_contact_no2')"
                  />
                </MCol>
                <MCol :size="6">
                  <FlotoFormItem
                    id="timezone-picker"
                    rules="required"
                    :label="$t('timezone')"
                  >
                    <FlotoTimeZonePicker v-model="formData.timezone" />
                  </FlotoFormItem>
                </MCol>
                <MCol v-if="type === 'requester'" :size="6">
                  <FlotoFormItem
                    id="vip-requester"
                    :label="`${$t('vip')} ${$tc('requester')}`"
                  >
                    <MRadioGroup
                      v-model="formData.vip"
                      as-button
                      :options="vipOptons"
                      :default-value="false"
                    />
                  </FlotoFormItem>
                </MCol>
                <MCol :size="6">
                  <FlotoFormItem id="location-picker" :label="$tc('location')">
                    <FlotoLocationPicker
                      v-model="formData.location"
                      :placeholder="$tc('location')"
                    />
                  </FlotoFormItem>
                </MCol>
                <MCol v-if="enableMsp" :size="6">
                  <FlotoFormItem id="company-picker" :label="$tc('company')">
                    <CompanyPicker
                      :key="renderCount"
                      v-model="formData.companyId"
                      :multiple="formData.userType === 'technician'"
                      :placeholder="$tc('company')"
                      as-input
                    />
                  </FlotoFormItem>
                </MCol>
                <MCol v-if="enableMsp && type === 'technician'" :size="6">
                  <FlotoFormItem
                    id="company-access-checkbox"
                    :label="$tc('restrict_data_access_to_own_company')"
                  >
                    <MSwitch
                      v-model="formData.restrictedCompanyAccess"
                      :disabled="!formData.companyId"
                      size="small"
                    />
                  </FlotoFormItem>
                </MCol>
                <MCol v-if="type === 'technician'" :size="12">
                  <FlotoFormItem
                    id="technician-picker"
                    :label="__t('signature')"
                  >
                    <FlotoRichEditor
                      v-model="formData.signature"
                      :max-height="150"
                      :affix-toolbar="false"
                      :placeholder="__t('signature')"
                    />
                  </FlotoFormItem>
                </MCol>
                <MCol :size="6">
                  <FlotoFormItem
                    id="department-picker"
                    :label="$tc('department')"
                  >
                    <FlotoDepartmentPicker
                      v-model="formData.department"
                      as-input
                    />
                  </FlotoFormItem>
                </MCol>
                <MCol :size="6">
                  <FlotoFormItem id="manager-picker" :label="$tc('manager')">
                    <ManagerPicker
                      v-model="formData.managerId"
                      as-input
                      :user-id="formData.id ? formData.id : 0"
                    />
                  </FlotoFormItem>
                </MCol>
                <MCol :size="6">
                  <FlotoFormItem
                    id="user-group-picker"
                    :label="$tc('group', 2)"
                  >
                    <FlotoTechnicianGroupPicker
                      v-if="type === 'technician'"
                      v-model="formData.groups"
                      multiple
                    />
                    <FlotoRequesterGroupPicker
                      v-else
                      v-model="formData.groups"
                      multiple
                    />
                  </FlotoFormItem>
                </MCol>
                <MCol v-if="type === 'technician'" :size="6">
                  <FlotoFormItem
                    id="support-level-picker"
                    :label="$tc('support_level')"
                  >
                    <FlotoSupportLevelPicker
                      v-model="formData.supportLevel"
                      multiple
                    />
                  </FlotoFormItem>
                </MCol>
                <MCol :size="12" class="flex items-center justify-between">
                  <FlotoFormItem
                    id="allow-to-login"
                    class="mr-4"
                    :label="$t('allow_to_login')"
                  >
                    <MSwitch
                      v-model="formData.allowToLogin"
                      :disabled="formData.id === user.id"
                      size="small"
                    />
                  </FlotoFormItem>
                  <FlotoFormItem
                    v-if="!formData.id"
                    id="verified"
                    class="mr-4"
                    :label="$tc('verified_user')"
                  >
                    <MSwitch v-model="formData.verified" size="small" />
                  </FlotoFormItem>
                  <FlotoFormItem
                    v-if="type === 'technician'"
                    id="do-not-disturb"
                    class="mr-4"
                    :label="__t('do_not_disturb')"
                  >
                    <MSwitch v-model="formData.doNotDisturb" size="small" />
                  </FlotoFormItem>
                </MCol>
                <MCol v-if="type === 'technician'" :size="6">
                  <FlotoFormItem
                    id="roles"
                    :label="$tc('role', 2)"
                    rules="required"
                  >
                    <FlotoRolePicker
                      v-model="formData.roles"
                      multiple
                      :disabled="formData.id && !formData.canDelete"
                      @super-admin-role="handleSelectedRoles"
                      @dashboard-viewer-role="
                        handleSelectedDashboardViewerRoles
                      "
                      @report-viewer-role="handleSelectedReportViewerRoles"
                    />
                  </FlotoFormItem>
                </MCol>
                <MCol :size="6">
                  <FlotoFormItem
                    id="identity-provider-picker"
                    :label="$tc('identity_provider')"
                  >
                    <SsoConfigPicker
                      v-model="formData.ssoConfigId"
                      :disabled="formData.ssoUser"
                      as-input
                      allow-clear
                    />
                  </FlotoFormItem>
                </MCol>
                <MCol v-if="type === 'technician'" :size="6">
                  <FlotoFormItem id="scope">
                    <template v-slot:label>
                      <div class="mb-1 flex">
                        <label>
                          {{ ` ${$tc('scope')}` }}
                        </label>
                        <MTooltip>
                          <template v-slot:trigger>
                            <MIcon
                              class="text-primary mx-2"
                              name="info-circle"
                              size="lg"
                            />
                          </template>
                          {{ $tc('scope_info') }}
                        </MTooltip>
                      </div>
                    </template>
                    <MRadioGroup
                      v-model="formData.scope"
                      :options="scopeOptions"
                      :disabled="
                        scopeDisabled ||
                        dashboardReportViewerRole ||
                        (formData.id && !formData.canDelete)
                      "
                    />
                  </FlotoFormItem>
                </MCol>
                <MCol :size="6" class="flex items-center justify-between">
                  <FlotoFormItem
                    id="allow-delegate-approval"
                    class="mr-4"
                    :label="$t('allow_delegate_approval')"
                  >
                    <MSwitch
                      v-model="formData.allowDelegateApproval"
                      size="small"
                      @change="handleAllowDelegateApprovalChange"
                    />
                  </FlotoFormItem>
                  <FlotoFormItem
                    v-if="type === 'technician'"
                    id="allow-work-delegation-for-self"
                    class="mr-4"
                    :label="$t('allow_work_delegation_for_self')"
                  >
                    <MSwitch
                      v-model="formData.allowWorkDelegationForSelf"
                      size="small"
                      :disabled="!formData.allowDelegateApproval"
                    />
                  </FlotoFormItem>
                  <FlotoFormItem
                    id="allow-delegate-approval-team"
                    class="mr-4"
                    :label="$t('allow_delegate_approval_team')"
                  >
                    <MSwitch
                      v-model="formData.allowDelegateApprovalTeam"
                      size="small"
                      @change="handleAllowDelegateApprovalTeamChange"
                    />
                  </FlotoFormItem>
                  <FlotoFormItem
                    v-if="type === 'technician'"
                    id="allow-work-delegation-for-team"
                    class="mr-4"
                    :label="$t('allow_work_delegation_for_team')"
                  >
                    <MSwitch
                      v-model="formData.allowWorkDelegationForTeam"
                      size="small"
                      :disabled="!formData.allowDelegateApprovalTeam"
                    />
                  </FlotoFormItem>
                </MCol>
                <MCol v-if="type === 'technician' && hasAssetModule" :size="6">
                  <FlotoFormItem
                    id="asset-management-scope"
                    :label="$tc('asset_scope')"
                  >
                    <MRadioGroup
                      v-model="formData.assetAccessLevel"
                      :options="scopeOptions"
                      :disabled="
                        scopeDisabled ||
                        dashboardReportViewerRole ||
                        (formData.id && !formData.canDelete)
                      "
                    />
                  </FlotoFormItem>
                </MCol>
                <MCol
                  v-if="formData.id && user.isSuperAdmin && enabledMfa"
                  :size="12"
                >
                  <div :style="{ position: 'relative' }">
                    <FlotoFormItem
                      :label="$t('two_factor_authentication')"
                      read-only
                      disabled
                    >
                      <div class="flex">
                        <MSwitch
                          id="enabled-switch"
                          v-model="formData.mfaEnrolled"
                          size="small"
                          disabled
                        />
                        <div v-if="formData.mfaEnrolled" class="ml-2">
                          <a @click="resetTwoFactor">
                            {{ $tc('reset') }}
                            {{ $tc('two_factor_authentication') }}
                          </a>
                        </div>
                      </div>
                    </FlotoFormItem>
                  </div>
                </MCol>
                <MCol :size="6">
                  <FlotoFormItem
                    rules="required"
                    :label="`${$tc('date')} ${$tc('time')} ${$tc('format')}`"
                  >
                    <FormatSelector
                      v-model="formData.dateTimeFormat"
                      format="dateTime"
                    />
                  </FlotoFormItem>
                </MCol>
                <MCol :size="6">
                  <FlotoFormItem
                    rules="required"
                    :label="`${$tc('date')} ${$tc('format')}`"
                  >
                    <FormatSelector
                      v-model="formData.dateFormat"
                      format="date"
                    />
                  </FlotoFormItem>
                </MCol>
                <MCol :size="6">
                  <FlotoFormItem
                    rules="required"
                    :label="`${$tc('time')} ${$tc('format')}`"
                  >
                    <FormatSelector
                      v-model="formData.timeFormat"
                      format="time"
                    />
                  </FlotoFormItem>
                </MCol>
              </MRow>

              <!-- <FlotoFormItem
                    v-model="formData.userLogOnName"
                    :label="__t('manager')"
                    class="w-1/2"
                    :placeholder="__t('manager')"
                  /> -->

              <div class="-mx-2">
                <FormConsumer
                  v-if="customFields.length"
                  v-model="formData.fieldValueDetails"
                  :form-fields="customFields"
                  :avoid-default-value="Boolean(formData.id)"
                  :with-submit="false"
                />
              </div>
            </MCol>
          </MRow>
        </MCol>
      </MRow>
      <FlotoConfirmModal
        :open="showModal"
        prevent-auto-close-on-confirm
        :processing="confirmProcessing"
        @hide="showModal = false"
        @confirm="handleConfirmAction"
      >
        <template v-slot:icon>
          <MIcon name="key" class="w-full text-primary" size="2x" />
        </template>
        <template v-slot:message>
          {{
            $t('confirm_reset_item', {
              item: `${$tc('two_factor_authentication')}`,
            })
          }}
        </template>
      </FlotoConfirmModal>
      <MDivider class="mt-0" />
      <template v-slot:submit>
        <span />
      </template>
    </FlotoForm>
  </FlotoContentLoader>
</template>

<script>
import { PreferenceComputed } from '@state/modules/preference'
import { UserSecurityConfigComputed } from '@state/modules/user-security-config'
import { authComputed } from '@state/modules/auth'
import { FormComputed } from '@state/modules/form'
import ImageSelector from '@components/image-selector'
import FormConsumer from '@components/form-consumer.vue'
import PasswordInput from '@components/password-input'
import { buildAvatar } from '@data/user'
import ManagerPicker from '@components/data-picker/manager-picker'
import CompanyPicker from '@components/data-picker/company-picker'
import { MspConfigComputed } from '@state/modules/msp-config/helpers'
import { autoDetectCompanyApi } from '@modules/organization/company-api'
import SsoConfigPicker from '@components/data-picker/sso-config-picker.vue'
import FormatSelector from '@components/date-format/format-selector'
import { userMfaReserApi } from '../users-api'
import Uniq from 'lodash/uniq'
import { emailRegEx } from '@src/validations'
import { LicenseComputed } from '@/src/state/modules/license'

export default {
  name: 'UserForm',
  components: {
    PasswordInput,
    FormConsumer,
    ImageSelector,
    ManagerPicker,
    CompanyPicker,
    SsoConfigPicker,
    FormatSelector,
  },
  props: {
    defaultValue: { type: Object, required: true },
    type: { type: String, required: true },
    processing: { type: Boolean, deafult: false },
  },
  data() {
    this.vipOptons = [
      { text: this.$t('yes'), value: true },
      { text: this.$t('no'), value: false },
    ]
    this.scopeOptions = [
      { text: this.__tc('global_access'), value: 'global_access' },
      { text: this.__tc('group_access'), value: 'group_access' },
      { text: this.__tc('restricted'), value: 'restricted' },
      { text: this.__tc('global_restricted'), value: 'global_restricted' },
    ]
    return {
      internalProcessing: false,
      formData: {
        ...this.defaultValue,
      },
      supperAdminId: undefined,
      dashboardViewerRoleId: undefined,
      reportViewerRoleId: undefined,
      scopeDisabled: false,
      renderCount: 1,
      showModal: false,
      confirmProcessing: false,
    }
  },
  computed: {
    ...FormComputed,
    ...PreferenceComputed,
    ...authComputed,
    ...MspConfigComputed,
    ...UserSecurityConfigComputed,
    ...LicenseComputed,
    usePassword() {
      return this.tenantPrefrences.showPassword
    },
    customFields() {
      return (this.userForm || {}).fields || []
    },
    loading() {
      return this.userLoading
    },
    globalProcessing() {
      return this.processing || this.internalProcessing
    },
    // dashboardViewerRole() {
    //   if (
    //     (this.formData.roles || []).length === 1 &&
    //     (this.formData.roles || []).indexOf(this.dashboardViewerRoleId) >= 0
    //   ) {
    //     return true
    //   }
    //   return false
    // },
    dashboardReportViewerRole() {
      if ((this.formData.roles || []).length === 1) {
        if (
          (this.formData.roles || []).indexOf(this.dashboardViewerRoleId) >=
            0 ||
          (this.formData.roles || []).indexOf(this.reportViewerRoleId) >= 0
        ) {
          return true
        }
        return false
      } else if ((this.formData.roles || []).length === 2) {
        if (
          (this.formData.roles || []).indexOf(this.dashboardViewerRoleId) >=
            0 &&
          (this.formData.roles || []).indexOf(this.reportViewerRoleId) >= 0
        ) {
          return true
        }
        return false
      }
      return false
    },
    hasAssetModule() {
      return this.availableModulesInLicense.indexOf(this.$constants.ASSET) >= 0
    },
  },
  watch: {
    'formData.roles': {
      immediate: true,
      handler(newValue, preValue) {
        if (newValue && newValue !== preValue) {
          if (newValue.indexOf(this.supperAdminId) >= 0) {
            this.scopeDisabled = true
            this.formData.scope = 'global_access'
          } else if (this.dashboardReportViewerRole) {
            this.scopeDisabled = true
            this.formData.scope = 'global_access'
          } else {
            this.scopeDisabled = false
          }
        }
      },
    },
    formData: {
      immediate: true,
      handler(newValue) {
        if (newValue.id && newValue.cxoUser === true) {
          this.scopeDisabled = true
        }
      },
    },
    'formData.companyId': {
      handler(newValue, preValue) {
        if (preValue && !newValue) {
          this.formData = {
            ...this.formData,
            restrictedCompanyAccess: false,
          }
        }
      },
    },
  },
  methods: {
    handleOnBlur(event) {
      if (event.target.value && emailRegEx.test(event.target.value)) {
        autoDetectCompanyApi(event.target.value).then((data) => {
          this.formData = {
            ...this.formData,
            ...(data.companyId
              ? {
                  companyId:
                    this.formData.userType === 'requester'
                      ? data.companyId
                      : Uniq([
                          data.companyId,
                          ...(this.formData.companyId || []),
                        ]),
                }
              : {}),
          }
          this.renderCount++
        })
      }
    },
    handleSelectedRoles(roles) {
      this.supperAdminId = ((roles || [])[0] || {}).id
    },
    handleSelectedDashboardViewerRoles(roles) {
      this.dashboardViewerRoleId = ((roles || [])[0] || {}).id
    },
    handleSelectedReportViewerRoles(roles) {
      this.reportViewerRoleId = ((roles || [])[0] || {}).id
    },
    generateProfilePic() {
      if (!(this.formData.profilePic || {}).refFileName) {
        this.handleRemoveAvatar()
      }
    },
    handleRemoveAvatar() {
      this.formData = {
        ...this.formData,
        profilePic: {},
        avatar: buildAvatar(
          { name: this.formData.name, email: this.formData.email },
          this.type === 'requester'
        ),
      }
    },
    handleSubmit() {
      this.internalProcessing = true
      this.$refs.profileSelector.getFile().then((profilePic) => {
        const data = {
          ...this.formData,
          ...(profilePic ? { profilePic } : {}),
          avatar: buildAvatar({ profilePic }),
          cxoUser: this.dashboardReportViewerRole,
        }
        this.$emit('submit', data)
        this.$nextTick(() => {
          this.internalProcessing = false
        })
      })
    },
    resetTwoFactor() {
      this.showModal = true
      // return userMfaReserApi(this.formData.id)
    },
    handleConfirmAction() {
      this.confirmProcessing = true
      userMfaReserApi(this.formData.id)
        .then(() => {
          this.showModal = false
          this.$router.push(
            this.$currentModule.getRoute('', {
              params: { userType: this.type },
            })
          )
        })
        .finally(() => {
          this.showModal = false
          this.confirmProcessing = false
        })
    },
    submit() {
      this.$refs.formRef.submit()
    },
    handleAllowDelegateApprovalChange(value) {
      if (value === false) {
        this.formData = {
          ...this.formData,
          allowWorkDelegationForSelf: false,
        }
      }
    },
    handleAllowDelegateApprovalTeamChange(value) {
      if (value === false) {
        this.formData = {
          ...this.formData,
          allowWorkDelegationForTeam: false,
        }
      }
    },
  },
}
</script>

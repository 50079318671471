<template>
  <MRow class="my-3 items-center" :gutter="0">
    <MCol
      v-if="hiddenFields.indexOf('groupId') === -1"
      id="technician-group-picker"
      :size="3"
    >
      <FlotoTechnicianGroupPicker
        :value="value.groupId"
        :suggested-value="suggestedGroupId"
        :disabled="
          (moduleName !== $constants.CHANGE && moduleName !== $constants.RELEASE
            ? disabled
            : isTicketClosed) || singleFieldRulesStateFn('groupId').disable
        "
        :placeholder="disabled ? '---' : undefined"
        :mandatory-selection="isRequiredFn('groupId')"
        v-bind="{
          hiddenOptionsKeys:
            singleFieldRulesStateFn('groupId').hiddenOptionsKeys || [],
          visibleOptionsKeys:
            singleFieldRulesStateFn('groupId').visibleOptionsKeys || [],
        }"
        @change="handleChange({ groupId: $event })"
      >
        <template v-slot:trigger="slotData">
          <FlotoDropdownTrigger
            :disabled="
              (moduleName !== $constants.CHANGE &&
              moduleName !== $constants.RELEASE
                ? disabled
                : isTicketClosed) || singleFieldRulesStateFn('groupId').disable
            "
            v-bind="slotData"
            :required="isRequiredFn('groupId')"
            :lable="$tc('technician_group', 2)"
            icon-name="users"
          />
        </template>
      </FlotoTechnicianGroupPicker>
    </MCol>
    <MCol
      v-if="hiddenFields.indexOf('technicianId') === -1"
      id="technician-picker"
      :size="3"
    >
      <FlotoTechnicianPicker
        size="default"
        :value="value.technicianId"
        :module-name="moduleName"
        :disabled="
          (moduleName !== $constants.CHANGE && moduleName !== $constants.RELEASE
            ? disabled
            : isTicketClosed) || singleFieldRulesStateFn('technicianId').disable
        "
        :group-id="value.groupId"
        :mandatory-selection="isRequiredFn('technicianId')"
        v-bind="{
          hiddenOptionsKeys:
            singleFieldRulesStateFn('technicianId').hiddenOptionsKeys || [],
          visibleOptionsKeys:
            singleFieldRulesStateFn('technicianId').visibleOptionsKeys || [],
        }"
        change-technician-on-group-change
        @change="handleChange({ technicianId: $event })"
      >
        <template v-slot:trigger="slotData">
          <FlotoDropdownTrigger
            :disabled="
              (moduleName !== $constants.CHANGE &&
              moduleName !== $constants.RELEASE
                ? disabled
                : isTicketClosed) ||
              singleFieldRulesStateFn('technicianId').disable
            "
            v-bind="slotData"
            :required="isRequiredFn('technicianId')"
            :lable="$tc('assignee')"
            icon-name="vector"
          >
            <template v-slot:icon>
              <FlotoUserAvatar
                :size="34"
                :avatar="(slotData.currentItem || {}).avatar"
                :style="{
                  minWidth: `${24}px`,
                }"
              />
              <!-- <div class="icon-container overflow-hidden" style="padding: 0;">
                <img
                  :src="(slotData.currentItem || {}).avatar"
                  class="w-full h-full"
                  style="border-top-left-radius: 4px; border-bottom-left-radius: 4px; transform: scale(1.4)"
                />
              </div> -->
            </template>
          </FlotoDropdownTrigger>
        </template>
        <!-- <template v-slot:selected-technician="{ technician }">
                <FlotoUserAvatar
                  size="default"
                  class="mr-2"
                  :avatar="technician.avatar"
                />
                {{ technician.name || technician.email }}
              </template> -->
      </FlotoTechnicianPicker>
    </MCol>
    <MCol id="due-date-picker" :size="3">
      <FlotoDueDatePicker
        :value="value.dueBy"
        :disabled="isDueByDisabled"
        :module-name="moduleName"
        :status-id="resource.statusId"
        icon-size="2x"
        as-dropdown
        @change="handleChange({ dueBy: $event })"
      >
        <template v-slot:after-text>
          <div class="w-full text-ellipsis">
            {{ dueByComputed | datetime }}
          </div>
        </template>
      </FlotoDueDatePicker>
    </MCol>
    <MCol
      v-if="moduleName === $constants.REQUEST"
      id="ola-due-date-picker"
      :size="3"
    >
      <FlotoDueDatePicker
        :value="value.olaDueBy"
        disabled
        :module-name="moduleName"
        :status-id="resource.statusId"
        icon-size="2x"
        prefix="ola"
        as-dropdown
      >
        <template v-slot:after-text>
          <div class="w-full">
            {{ olaDueByComputed | datetime }}
          </div>
        </template>
      </FlotoDueDatePicker>
    </MCol>
    <MCol
      v-if="
        moduleName === $constants.CHANGE &&
        hiddenFields.indexOf('riskTypeId') === -1
      "
      id="risk-type-picker"
      :size="3"
    >
      <FlotoRiskTypePicker
        :value="value.riskTypeId"
        :disabled="disabled || singleFieldRulesStateFn('riskTypeId').disable"
        :placeholder="disabled ? '---' : undefined"
        v-bind="{
          hiddenOptionsKeys:
            singleFieldRulesStateFn('riskTypeId').hiddenOptionsKeys || [],
          visibleOptionsKeys:
            singleFieldRulesStateFn('riskTypeId').visibleOptionsKeys || [],
        }"
        @change="handleChange({ riskTypeId: $event })"
      >
        <template v-slot:trigger="slotData">
          <FlotoDropdownTrigger
            :disabled="
              disabled || singleFieldRulesStateFn('riskTypeId').disable
            "
            v-bind="slotData"
            :lable="$tc('change_risk')"
            icon-name="exclamation-circle"
          />
        </template>
      </FlotoRiskTypePicker>
    </MCol>
    <MCol
      v-if="
        moduleName === $constants.RELEASE &&
        hiddenFields.indexOf('riskTypeId') === -1
      "
      id="release-risk-type-picker"
      :size="3"
    >
      <ReleaseRiskTypePicker
        :value="value.riskTypeId"
        :placeholder="disabled ? '---' : undefined"
        @change="handleChange({ riskTypeId: $event })"
      >
        <template v-slot:trigger="slotData">
          <FlotoDropdownTrigger
            :disabled="disabled"
            v-bind="slotData"
            :lable="$tc('release_risk')"
            icon-name="exclamation-circle"
          />
        </template>
      </ReleaseRiskTypePicker>
    </MCol>
    <!-- category picker <MCol :size="3">
      <MTooltip class="flex items-center" placement="topLeft">
        <template v-slot:trigger>
          <MIcon name="layer-group" size="2x" class="mr-4 text-neutral-light" />
          <div class="w-full">
            <div>
              <FlotoCategoryPicker
                :value="value.categoryId"
                :class="{ 'read-only': disabled, 'material-input': !disabled }"
                :disabled="disabled"
                :placeholder="disabled ? '---' : undefined"
                class="w-full"
                :module-name="moduleName"
                @change="handleChange({ categoryId: $event })"
              />
            </div>
          </div>
        </template>
        {{ $tc('category') }}
      </MTooltip>
    </MCol> -->
  </MRow>
</template>

<script>
import ReleaseRiskTypePicker from '@components/data-picker/release-risk-type-picker'
export default {
  name: 'SecondRow',
  components: { ReleaseRiskTypePicker },
  inject: ['suggestedFieldValuesContext'],
  model: {
    event: 'change',
  },
  props: {
    value: { type: Object, required: true },
    isTicketClosed: { type: Boolean, default: false },
    resource: { type: Object, required: true },
    disabled: { type: Boolean, default: false },
    moduleName: { type: String, required: true },
    hiddenFields: {
      type: Array,
      default() {
        return []
      },
    },
    isRequiredFn: {
      type: Function,
      default: (e) => e,
    },
    singleFieldRulesStateFn: {
      type: Function,
      default: (e) => e,
    },
    // eslint-disable-next-line
    isAllowedToEditDueBy: { type: Boolean, default: true },
  },
  computed: {
    dueByComputed() {
      return Math.abs(this.resource.dueBy)
    },
    olaDueByComputed() {
      return Math.abs(this.resource.olaDueBy)
    },
    suggestedGroupId() {
      let value
      const fieldValue =
        (
          (this.suggestedFieldValuesContext || {}).suggestedFieldValues || []
        ).find((i) => i.refId === this.resource.id) || {}
      if ((fieldValue || {}).suggestedGroupId) {
        value = fieldValue.suggestedGroupId
      }
      return value
    },
    isDueByDisabled() {
      if (!this.isAllowedToEditDueBy && this.resource.violatedSlaId > 0) {
        return true
      }
      return this.disabled || this.value.dueBy < 0
    },
  },
  methods: {
    handleChange(change) {
      this.$emit('change', change)
    },
  },
}
</script>

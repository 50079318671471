<template>
  <FlotoModule>
    <RouterView :key="$route.fullPath" />
  </FlotoModule>
</template>

<script>
import Capitalize from 'lodash/capitalize'
import Constants from '@constants'
import { StatusActions } from '@state/modules/status'
export default {
  name: 'StatusModule',
  page() {
    return {
      title: this.$tc('status'),
    }
  },
  beforeRouteEnter(to, from, next) {
    const allowedForms = [
      Constants.REQUEST,
      Constants.PROBLEM,
      Constants.CHANGE,
      Constants.ASSET,
      Constants.RELEASE,
      Constants.TASK,
    ]
    if (
      to.params.moduleName &&
      allowedForms.indexOf(to.params.moduleName) === -1
    ) {
      return next({ name: '404' })
    }
    next()
  },
  beforeRouteLeave(to, from, next) {
    if (
      from.params.moduleName &&
      this[`fetch${Capitalize(from.params.moduleName)}Status`]
    ) {
      this[`fetch${Capitalize(from.params.moduleName)}Status`]()
    }
    next()
  },
  methods: {
    ...StatusActions,
  },
}
</script>

import api from '@api'
import { successToast } from '@motadata/ui'
import {
  getRootTranslator,
  getRootPluaralTranslator,
  getModuleTranslator,
} from '@utils/get-module-translator'
import {
  transformBatchQualForServer,
  buildNameFilterQuery,
  buildRelationalQualificationStructure,
  buildFlatQualificationStructure,
} from '@data/qualification'
import {
  transformUserForList,
  transformUser,
  transformUserForServer,
  transformSuperAdminForServer,
  transformUserForVuex,
} from '@data/user'
import { transformExportGridDataForServer } from '@data/report'
import { isPortalLogin } from '@utils/auth'
import config from './config'

const __rootTc = getRootPluaralTranslator()
const __rootT = getRootTranslator()
const __t = getModuleTranslator(config.translationKey)

const buildQualifications = (qualificationFactors) => {
  const quals = []
  if (qualificationFactors.name) {
    const q = transformBatchQualForServer(
      [
        buildNameFilterQuery(qualificationFactors.name),
        buildRelationalQualificationStructure(
          'email',
          'contains',
          qualificationFactors.name,
          'string',
          'db'
        ),
      ],
      'or'
    )
    quals.push(q)
  }
  if (
    qualificationFactors.selectedName &&
    qualificationFactors.selectedName.length
  ) {
    quals.push(buildNameFilterQuery(qualificationFactors.selectedName, 'in'))
  }
  return buildFlatQualificationStructure(quals)
}

const verifiedQuery = (value) =>
  buildRelationalQualificationStructure(
    'verified',
    'equal',
    value,
    'boolean',
    'db'
  )

const cxoUserQuery = (value) =>
  buildRelationalQualificationStructure(
    'cxoUser',
    'equal',
    value,
    'boolean',
    'db'
  )

const deletedQuery = (value) =>
  buildRelationalQualificationStructure(
    'removed',
    'equal',
    value,
    'boolean',
    'db'
  )

const blockedQuery = (value) =>
  buildRelationalQualificationStructure(
    'disabled',
    'equal',
    value,
    'boolean',
    'db'
  )

export function getUsersApi(
  type,
  filters,
  searchCriteria,
  limit,
  offset,
  params = {}
) {
  const qualDetails = [...((searchCriteria || {}).quals || [])]
  if ('name' in filters && filters.name) {
    qualDetails.push(buildNameFilterQuery(filters.name))
  }
  if ('selectedName' in filters && filters.selectedName) {
    qualDetails.push(buildNameFilterQuery(filters.selectedName))
  }
  if ('verified' in filters) {
    qualDetails.push(verifiedQuery(true))
  }
  if ('cxoUser' in filters) {
    qualDetails.push(cxoUserQuery(true))
    qualDetails.push(cxoUserQuery(false))
  }
  if ('onlyCxoUser' in filters) {
    qualDetails.push(cxoUserQuery(true))
  }
  if ('unverified' in filters) {
    qualDetails.push(verifiedQuery(false))
  }
  if ('archived' in filters) {
    qualDetails.push(deletedQuery(true))
  }
  if ('blocked' in filters) {
    qualDetails.push(blockedQuery(true))
  }
  if ('unblocked' in filters) {
    qualDetails.push(blockedQuery(false))
  }
  if ('archivedAndUnarchived' in filters) {
    qualDetails.push(deletedQuery(true))
    qualDetails.push(deletedQuery(false))
  }
  if ('ids' in filters) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'id',
        'in',
        filters.ids,
        'long',
        'db'
      )
    )
  }
  if ('excludedIds' in filters) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'id',
        'not_in',
        filters.excludedIds,
        'long',
        'db'
      )
    )
  }
  return api
    .post(
      `${type}/search/byqual`,
      {
        qualDetails: qualDetails.length
          ? buildFlatQualificationStructure(qualDetails)
          : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...params,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformUserForList),
        total: data.totalCount,
      }
    })
}

export function getUsersPublicApi(
  type,
  filters,
  searchCriteria,
  limit,
  offset,
  params = {}
) {
  const qualDetails = [...((searchCriteria || {}).quals || [])]
  if ('name' in filters && filters.name) {
    qualDetails.push(buildNameFilterQuery(filters.name))
  }
  if ('selectedName' in filters && filters.selectedName) {
    qualDetails.push(buildNameFilterQuery(filters.selectedName))
  }
  if ('verified' in filters) {
    qualDetails.push(verifiedQuery(true))
  }
  if ('unverified' in filters) {
    qualDetails.push(verifiedQuery(false))
  }
  if ('archived' in filters) {
    qualDetails.push(deletedQuery(true))
  }
  if ('archivedAndUnarchived' in filters) {
    qualDetails.push(deletedQuery(true))
    qualDetails.push(deletedQuery(false))
  }
  if ('ids' in filters) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'id',
        'in',
        filters.ids,
        'long',
        'db'
      )
    )
  }
  return api
    .post(
      `public/user/search/byqual`,
      {
        qualDetails: qualDetails.length
          ? buildFlatQualificationStructure(qualDetails)
          : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...params,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformUserForList),
        total: data.totalCount,
      }
    })
}

export function getRequestersApi(
  publicApi = false,
  filters,
  limit,
  offset,
  params = {}
) {
  const qualDetails = []
  if ('name' in filters && filters.name) {
    qualDetails.push(buildNameFilterQuery(filters.name))
  }
  if ('selectedName' in filters && filters.selectedName) {
    if (Array.isArray(filters.selectedName)) {
      qualDetails.push(buildNameFilterQuery(filters.selectedName, 'in'))
    } else {
      qualDetails.push(buildNameFilterQuery(filters.selectedName))
    }
  }
  if ('archivedAndUnarchived' in filters) {
    qualDetails.push(deletedQuery(true))
    qualDetails.push(deletedQuery(false))
  }
  if ('ids' in filters && filters.ids.length) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'id',
        'in',
        filters.ids,
        'long',
        'db'
      )
    )
  }
  if ('allowToLogin' in filters) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'allowToLogin',
        'equal',
        filters.allowToLogin,
        'boolean',
        'db'
      )
    )
  }
  if ('excludedIds' in filters && filters.excludedIds.length) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'id',
        'not_in',
        filters.excludedIds,
        'long',
        'db'
      )
    )
  }
  return api
    .post(
      `${publicApi ? '/public' : ''}/requester/search`,
      {
        qualDetails: qualDetails.length
          ? buildFlatQualificationStructure(qualDetails)
          : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...params,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformUserForList),
        total: data.totalCount,
      }
    })
}

export function restoreUsersApi(type, users, data, ignoreCount = false) {
  const payload = {
    userIds: users,
    ...(data && Object.keys(data).length
      ? {
          updateMap: data,
        }
      : {}),
  }
  return api.patch(`${type}/bulk/restore`, payload).then((data) => {
    if (data.successIds && data.successIds.length) {
      successToast(
        `${ignoreCount ? '' : data.successIds.length} ${__rootT(
          data.successIds.length > 1
            ? 'restored_successfully_multiple'
            : 'restored_successfully',
          {
            resource: __rootTc(type, data.successIds.length),
          }
        )}`
      )
    }
    return {
      failed: Object.keys(data.failerIds || {}).map((key) => ({
        id: parseInt(key, 10),
        message: data.failerIds[key].userMessage,
      })),
    }
  })
}

export function blockUsersApi(type, users, isBlock) {
  return api
    .patch(
      `${type}/bulk/block`,
      {
        userIds: users,
      },
      {
        params: {
          isBlock: isBlock,
        },
      }
    )
    .then((data) => {
      if (data.successIds && data.successIds.length) {
        if (isBlock === true) {
          successToast(
            `${data.successIds.length} ${__rootT(
              data.successIds.length > 1
                ? 'blocked_successfully_multiple'
                : 'blocked_successfully',
              {
                resource: __rootTc(type, data.successIds.length),
              }
            )}`
          )
        } else if (isBlock === false) {
          successToast(
            `${data.successIds.length} ${__rootT(
              data.successIds.length > 1
                ? 'unblocked_successfully_multiple'
                : 'unblocked_successfully',
              {
                resource: __rootTc(type, data.successIds.length),
              }
            )}`
          )
        }
      }
      return {
        failed: Object.keys(data.failerIds || {}).map((key) => ({
          id: parseInt(key, 10),
          message: data.failerIds[key].userMessage,
        })),
      }
    })
}

export function bulkConvertUsersApi(type, users, usertype) {
  return api
    .patch(`/user/bulk/convert/${usertype}`, {
      userIds: users,
    })
    .then((data) => {
      if (data.successIds && data.successIds.length) {
        if (type === 'requester') {
          successToast(__rootTc('convert_to_technician_successfully'))
        } else {
          successToast(__rootTc('convert_to_requester_successfully'))
        }
      }
      return {
        failed: Object.keys(data.failerIds || {}).map((key) => ({
          id: parseInt(key, 10),
          message: data.failerIds[key].userMessage,
        })),
      }
    })
}

export function createUserApi(type, data) {
  return api
    .post(`${type}`, transformUserForServer(data), {
      message: __rootT('created_successfully', {
        resource: __rootTc(type),
      }),
    })
    .then(transformUser)
}

export function changeSuperAdmin(data) {
  return api.post('/convert/superadmin', transformSuperAdminForServer(data), {
    message: __rootT('changed_successfully', {
      resource: __rootTc('super_admin'),
    }),
  })
}

export function getUserApi(type, id, params = {}) {
  return api.get(`${type}/${id}`, params).then((u) => transformUser(u, type))
}
export function unLockUserApi(id) {
  return api.get(`/user/${id}/unlock`)
}

export function getGlobalUserApi(id, additionaParams = {}) {
  return api
    .get(`user/${id}`, {
      params: {
        ...additionaParams,
      },
    })
    .then(transformUser)
}

export function updateUserApi(type, data, message) {
  return api
    .patch(
      `${isPortalLogin() ? '/cportal' : ''}/${type}${
        isPortalLogin() ? '' : `/${data.id}`
      }`,
      transformUserForServer(data),
      {
        message:
          message ||
          __rootT('updated_successfully', {
            resource: __rootTc(type),
          }),
      }
    )
    .then((u) => transformUser(u, type))
}

export function convertUserApi(type, data) {
  return api.get(`/user/${data.id}/convert/${data.userType}`, {
    message: __rootT('updated_successfully', {
      resource: __rootTc(type),
    }),
  })
}

export function deleteUserApi(type, id) {
  return api.delete(`${type}/${id}`, {
    message: __rootT('archived_successfully', {
      resource: __rootTc(type),
    }),
  })
}

export function bulkDeleteUserApi(type, ids) {
  return api
    .delete(`${type}/bulk/delete`, {
      data: {
        userIds: ids,
      },
    })
    .then((data) => {
      if (data.successIds && data.successIds.length) {
        successToast(
          `${data.successIds.length} ${__rootT(
            data.successIds.length > 1
              ? 'archived_successfully_multiple'
              : 'archived_successfully',
            {
              resource: __rootTc(type, data.successIds.length),
            }
          )}`
        )
      }
    })
}

export function sendVerificationEmailApi(id) {
  return api.get(`/requester/sendactivation/email/${id}`, {
    message: __t('email_sent_successfully'),
  })
}

export function searchUserByEmailApi(
  publicApi = false,
  filters,
  value,
  isEmail
) {
  const qualDetails = []
  if ('name' in filters && filters.name) {
    qualDetails.push(buildNameFilterQuery(filters.name))
  }
  if ('selectedName' in filters && filters.selectedName) {
    if (Array.isArray(filters.selectedName)) {
      qualDetails.push(buildNameFilterQuery(filters.selectedName, 'in'))
    } else {
      qualDetails.push(buildNameFilterQuery(filters.selectedName))
    }
  }
  if ('archivedAndUnarchived' in filters) {
    qualDetails.push(deletedQuery(true))
    qualDetails.push(deletedQuery(false))
  }
  if ('ids' in filters && filters.ids.length) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'id',
        'in',
        filters.ids,
        'long',
        'db'
      )
    )
  }
  if ('allowToLogin' in filters) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'allowToLogin',
        'equal',
        filters.allowToLogin,
        'boolean',
        'db'
      )
    )
  }
  if ('excludedIds' in filters && filters.excludedIds.length) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'id',
        'not_in',
        filters.excludedIds,
        'long',
        'db'
      )
    )
  }
  return api
    .post(
      `${
        publicApi ? '/public' : ''
      }/requester/search/email?fullObject=true&query=${encodeURIComponent(
        value
      )}`,
      {
        qualDetails: qualDetails.length
          ? buildFlatQualificationStructure(qualDetails)
          : undefined,
      }
    )
    .then((data) => {
      return {
        items: (data || []).map(transformUserForVuex),
        responseSearchTerm: value,
      }
    })
}

export function exportUsersApi(filters, searchCriteria, data) {
  const qualDetails = [...((searchCriteria || {}).quals || [])]
  if ('name' in filters) {
    qualDetails.push(buildNameFilterQuery(filters.name))
  }
  if ('verified' in filters) {
    qualDetails.push(verifiedQuery(true))
  }
  if ('unverified' in filters) {
    qualDetails.push(verifiedQuery(false))
  }
  if ('archived' in filters) {
    qualDetails.push(deletedQuery(true))
  }
  return api.post(
    '/analytics/export/griddata',
    {
      ...transformExportGridDataForServer(data),
      qualification: qualDetails.length
        ? buildFlatQualificationStructure(qualDetails)
        : undefined,
    },
    {
      message: __rootT('export_successfully', {
        resource: __rootTc(data.model),
      }),
    },
    {
      notification: {
        error: {
          message: __rootT('error'),
        },
      },
    }
  )
}

export function getManagersApi(userId, filter, limit, offset, urlParams = {}) {
  return api
    .post(
      `/user/manager/${userId}`,
      {
        qualDetails:
          filter.selectedName || filter.name
            ? buildQualifications(filter)
            : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...urlParams,
        },
      }
    )
    .then((data) => {
      return {
        items: data.objectList || [],
        total: data.totalCount,
      }
    })
}

export function getActiveTechniciansApi() {
  return api.get('/technician/active/list')
}
export function userMfaReserApi(id) {
  return api.post(`/user/${id}/mfa/reset`, {})
}
export function mfaRegenerateRecoveyCodeApi(token) {
  return api.post(`/mfa/regenerate/recoverycode?mid=${token}`, {})
}

export function bulkUpdateApi(type, ids, data, ignoreCount = false) {
  const payload = {
    objectIds: ids,
    updateMap: data,
  }
  return api
    .patch(`/user/bulk/update`, payload, {
      notify: false,
    })
    .then((data) => {
      if (data.successIds && data.successIds.length) {
        successToast(
          `${ignoreCount ? '' : data.successIds.length} ${__rootT(
            'data_cleared_successfully',
            {
              resource: __rootTc(type, data.successIds.length),
            }
          )}`
        )
      }
      return {
        failed: Object.keys(data.failerIds || {}).map((key) => ({
          id: parseInt(key, 10),
          message: data.failerIds[key].userMessage,
        })),
      }
    })
}
